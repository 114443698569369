<template>
    <div
        class="cursor-pointer"
        ref="container" style="width: 100%; height: 100%;"></div>
</template>
<style>
.cursor-pointer canvas {
    cursor: pointer !important;
}
</style>
<script setup>

import {onMounted, ref} from "vue";

const container = ref()
const props = defineProps(['file']);
const wave = ref()

onMounted(() => {
    wave.value = new CircularAudioWave(container.value);
    wave.value.loadAudio(props.file);
});

const play = () => {
    wave.value.play();
}

const pause = () => {
    wave.value.pause();
}

defineExpose({
    play,
    pause,
    wave
})
</script>
